<template>
  <div id="app">
    <div class="bg"></div>
    <div class="module-border-wrap">
      <div class="contain">
        <div class="logo" ref="logo">
          <a href="https://knowprep.in/">
            <img src="@/assets/logo_transparent.png" />
          </a>
          
        </div>
        <div class="myform d-flex">
          <form @submit.prevent="login">
            <div class="form-group mx-5 my-2 py-3">
              <input
                ref="mail"
                v-model="email"
                type="email"
                class="form-control my-2"
                placeholder="Email"
              />
              <input
                ref="pass"
                v-model="password"
                type="password"
                class="form-control my-2"
                placeholder="Password"
              />
              <button ref="btn" type="submit" class="btn my-4">Login</button>
              <p>Need an account? contact administrator</p>
              <button @click.prevent="redirect" class="btn btn-dark">
                Try Demo Quiz
              </button>
            </div>
                       <!-- <div>-->
            <!--              <button @click.prevent="redirect" class="btn btn-dark">-->
            <!--                Try Demo Quiz-->
            <!--              </button>-->
            <!--            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import db from "@/firebase";
import firebase from "firebase/app";
import "firebase/auth";
import gsap from "gsap";
import store from "@/store";

console.log(db)

export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  mounted() {
    store.state.loading = false;
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(data => {})
        .catch(err => swal(err.message));
    },
    redirect() {
      this.$router.push({
        path: "/demo"
      });
    }
  }
};
</script>
<style scoped>
.logo img {
  height: 125px;
  width: 125px;
}

#app {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: rgb(255, 245, 245);
}

.contain {
  right: 0;
  background-color: rgba(255, 255, 255);
  width: 30.5vw;
  height: 493px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  z-index: 3;
}

.module-border-wrap {
  width: 31vw;
  height: 500px;
  position: absolute;
  background: linear-gradient(
    -20deg,
    rgb(255, 0, 0),
    rgba(213, 2, 255, 0.356),
    rgba(161, 124, 248, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  padding-left: 4px;
  padding-top: 4px;
  left: 44vw;
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #444;
  margin-bottom: 10px;
}

.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 150px;
  background-color: darksalmon;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  margin: auto;
  overflow: hidden;
}

.bg {
  position: fixed;
  background: rgb(255, 255, 255);
  width: 40vw;
  height: 100vh;
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
  top: -55vh;
  left: 30.5vw;
  z-index: 1;
  border-radius: 100px;
}

* {
  overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
  .module-border-wrap {
    width: 100vw;
    height: 100vh;
    left: 10%;
    overflow-x: hidden;
  }

  .contain {
    width: 100vw;
    height: 100vh;
  }

  .bg {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .module-border-wrap {
    width: 80vw;
    height: 80vh;
    left: 10%;
    overflow-x: hidden;
  }

  .contain {
    width: 80vw;
    height: 80vh;
  }

  .bg {
    display: none;
  }
}
</style>
